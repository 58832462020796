import http from '@/services/helpers/http';
import createdDateIdMock from '../temp/created_date_id.mock.json';
import paymentDateIdMock from '../temp/payment_date_id.mock.json';
import countryIdMock from '../temp/country_id.mock.json';
import companyIdMock from '../temp/company_id.mock.json';
import userIdMock from '../temp/user_id.mock.json';
import productIdMock from '../temp/product_id.mock.json';
import stateIdMock from '../temp/state_id.mock.json';
import paymentStateIdMock from '../temp/payment_state_id.mock.json';
import paymentInDaysStateIdMock from '../temp/payment_in_days_state_id.mock.json';
import paymentExpiredStateIdMock from '../temp/payment_expired_state_id.mock.json';
import yieldYearIdMock from '../temp/yield_year_id.mock.json';
import priceSaleIdMock from '../temp/price_sale_id.mock.json';
import extensionInAdvanceIdMock from '../temp/extension_in_advance_id.mock.json';

class StatisticServiceClass {
	async getStatistics() {
		return http.get('statistic/getStatisticViewList').then((res) => res.data);
	}

	async getStatistic(statisticType) {
		const data = {
			id: statisticType,
		};

		return http.get('statistic/getStatisticView', data).then((res) => res.data);
	}

	async getDataStructure(statisticType, activeView, activeSubview) {
		const data = {
			id: statisticType,
			view: activeView,
		};

		if (activeSubview !== null && activeSubview !== undefined) {
			data.subView = activeSubview;
		}

		return http.get('statistic/GetFactsView', data).then((res) => res.data);
		// .then((res) => {
		// 	// TODO remove when BE graph works
		// 	switch (activeView) {
		// 		case 'country_id':
		// 			res.Graph = countryIdMock;
		// 			break;
		// 		case 'created_date_id':
		// 			res.Graph = createdDateIdMock;
		// 			break;
		// 		case 'payment_date_id':
		// 			res.Graph = paymentDateIdMock;
		// 			break;
		// 		case 'company_id':
		// 			res.Graph = companyIdMock;
		// 			break;
		// 		case 'user_id':
		// 			res.Graph = userIdMock;
		// 			break;
		// 		case 'product_id':
		// 			res.Graph = productIdMock;
		// 			break;
		// 		case 'state_id':
		// 			res.Graph = stateIdMock;
		// 			break;
		// 		case 'payment_state_id':
		// 			res.Graph = paymentStateIdMock;
		// 			break;
		// 		case 'payment_in_days_state_id':
		// 			res.Graph = paymentInDaysStateIdMock;
		// 			break;
		// 		case 'payment_expired_state_id':
		// 			res.Graph = paymentExpiredStateIdMock;
		// 			break;
		// 		case 'yield_year_id':
		// 			res.Graph = yieldYearIdMock;
		// 			break;
		// 		case 'price_sale_id':
		// 			res.Graph = priceSaleIdMock;
		// 			break;
		// 		case 'extension_in_advance_id':
		// 			res.Graph = extensionInAdvanceIdMock;
		// 			break;
		// 	}
		//	return res;
		//});
	}

	async getStructure(statisticType) {
		const ms = await this.getStatistic(statisticType);

		if (ms && ms.Inputs.ViewList && ms.Inputs.ViewList.length) {
			// TODO make user specific, store in user profile
			const activeView = ms.Inputs.ViewList[0];
			const activeSubView = activeView.DefaultSubView;
			ms.ActiveView = { ActiveView: activeView.Id, ActiveSubView: activeSubView };
			const ds = await this.getDataStructure(statisticType, activeView.Id, activeSubView);

			return { main: ms, data: ds };
		}

		ms.ActiveView = { ActiveView: null, ActiveSubView: null };

		return { main: ms };
	}

	async reloadFacts(statisticType) {
		return http.get('statistic/ReloadFacts', { id: statisticType }).then((res) => res.data);
	}

	async reloadFactsInfo(statisticType) {
		return http.get('statistic/GetReloadInfo', { id: statisticType }).then((res) => res.data);
	}

	async getTableStatistics({ statisticType, active, tableData, filters }) {
		//console.debug('getTableStatistics', active);
		let data = {
			id: statisticType,
			view: active.ActiveView,
			subView: active.ActiveSubView,
			...tableData,
			...filters,
		};

		// TODO remove, when pagnation works
		delete data.perPage;

		data = Object.entries(data).filter(([k, v]) => v !== null && v !== undefined);
		data = Object.fromEntries(data);

		return http.get('statistic/getFacts', data).then((res) => res.data);
	}
}

const StatisticService = new StatisticServiceClass();

export { StatisticService };
